.modal-container .modal-button-container {
    position: relative;
    margin: 0;
    padding: 0;
}

.modal {
    background: rgba(116, 120, 148, 0.2);
    display: none !important;
}

.onlyBody .modal-content {
    width: fit-content !important;
}

.onlyBody .modal-dialog {
    width: fit-content;
}

.show {
    display: block !important;
}

.modal-dialog {
    animation: opt-animation3 0.23s;
    animation-fill-mode: forwards;
}

@media (max-width: 992px) {
    .modal-dialog {
        height: 100%;
    }
}

.modal .modal-dialog {
    max-width: 772px !important;
}

.modal.modal-center .modal-dialog {
    max-width: 539px !important;
    top: 50vh;
    animation: opt-animation4 0.23s;
    animation-fill-mode: forwards;
}

.modal-backdrop {
    background-color: #f2f2f2 !important;
}

@-webkit-keyframes opt-animation3 {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@-moz-keyframes opt-animation3 {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@-o-keyframes opt-animation3 {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes opt-animation3 {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@-webkit-keyframes opt-animation4 {
    0% {
        opacity: 0;
        transform: scale(0) translateY(-50vh);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(-50vh);
    }
}

@-moz-keyframes opt-animation4 {
    0% {
        opacity: 0;
        transform: scale(0) translateY(-50vh);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(-50vh);
    }
}

@-o-keyframes opt-animation4 {
    0% {
        opacity: 0;
        transform: scale(0) translateY(-50vh);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(-50vh);
    }
}

@keyframes opt-animation4 {
    0% {
        opacity: 0;
        transform: scale(0) translateY(-50vh);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(-50vh);
    }
}
