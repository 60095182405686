.App {
    text-align: center;
}
body {
    background: #fafafc;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #ffffff;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

label {
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 20px !important;
    letter-spacing: 0.1875px !important;
    color: #24252f !important;
}

button {
    transition: transform 0.4s ease-in-out;
}

*:focus {
    outline: none !important;
}

.tooltip-inner {
    max-width: fit-content;
    padding: 0;
}

.arrow::before {
    border-right-color: white !important;
}
